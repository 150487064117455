import React, { useState, useEffect } from 'react';
import { get, post, put, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle   } from "react-icons/bs";
import Select from 'react-select';


const PendingInvoices = ({ onAddItem, onUpdateItem, onDeleteItem }) => {
  const [data, setData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [showCalendar, setShowCalendar] = useState(false)
  const [searchIconClicked, setSearchIconClicked] = useState(false);;
  const [itemOptions, setItemOptions] = useState([]);
  const [note, setNote] = useState('');
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [newItem, setNewItem] = useState({
    date: '',
    channel_ref: '',
    pip: '',
    item: '',
    pack_size: '',
    order_qnt: '',
    approved_qnt: '',
    unit_price_ex_vat: '',
    channel: '',
    sub_totals: '',
    received: '',
    notes: '',
  });
  
  const [updateItem, setUpdateItem] = useState({
    date: '',
    channel_ref: '',
    pip: '',
    item: '',
    pack_size: '',
    order_qnt: '',
    approved_qnt: '',
    unit_price_ex_vat: '',
    channel: '',
    sub_totals: '',
    received: '',
    notes: '',
  });
  
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 100;

  useEffect(() => {
    fetchData(); // Fetch initial data on component mount
    fetchPipData(); // Fetch item options
  }, []);

  const fetchData = async () => {
    try {
      const response = await get('api/invoices');
      //console.log(response)
      const filteredOptions = response.data.filter((item) => item.received === 'PENDING');
      const sortedData = filteredOptions.sort((a, b) => new Date(b.date) - new Date(a.date));
      setData(sortedData);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const fetchPipData = async () => {
    try {
      const response = await get('api/ppidProductInfo/pip');
      const newOptions = response.data.map((item) => ({ product: item.product, pip: item.pip }));
      setItemOptions(newOptions);
    } catch (error) {
      console.error('Error fetching ppid:', error);
    }
  };


  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
    setShowCalendar(false); // Hide the calendar after selection
  };

  const filteredData = data.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'id') return true; // Exclude 'id' from search
      if (key === 'date') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedData = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();

  
    const handleUpdateReceived = async (item) => {
    try {

      console.log(item.received)
      console.log(item.notes)

      if (item.received === 'NO') {
        setUpdateItem(item);
        setSelectedItemId(item.id)
        setShowNotePopup(true);
      } 

      else {
      await put(`api/invoices/${item.id}`, item);

      fetchData();
      // onUpdateItem();
      }
    } catch (error) {
      console.error('Error updating Received:', error);
    }
  };



  const handleNoteSubmit = () => {

    console.log(note)
    updateItem.notes = note;

    handleUpdateItem();
    setShowNotePopup(false);
    setNote('');
  };
  

  const handleAddItem = async () => {

    console.log('newItem',newItem);

    const cleansedNewItem = {
        ...newItem,
        order_qnt: parseInt(newItem.order_qnt),
        approved_qnt: parseInt(newItem.approved_qnt),
        unit_price_ex_vat: parseFloat(newItem.unit_price_ex_vat),
        sub_totals: parseFloat(newItem.approved_qnt)* parseFloat(newItem.unit_price_ex_vat),
        
      };
    
      // Validation
      if (!Number.isInteger(cleansedNewItem.order_qnt) || !Number.isInteger(cleansedNewItem.approved_qnt)) {
        console.warn('Order Quantity and Approved Quantity must be integers');
        return;
      }
    
      if (isNaN(cleansedNewItem.unit_price_ex_vat)) {
        console.warn('Unit Price (Ex VAT) and Subtotals must be numbers');
        return;
      }
    
      if (cleansedNewItem.received !== 'YES' && cleansedNewItem.received !== 'NO' && cleansedNewItem.received !== 'PENDING') {
        console.warn('Received value must be "YES" or "NO" or "PENDING"');
        return;
      }

    try {
    //     const [splitNewPip, splitNewItem] = cleansedNewItem.item.split(' - ');
    //     const updatedNewItem = {
    //       ...cleansedNewItem,
    //       item: splitNewItem,
    //       pip: splitNewPip,
    //     };

        console.log('updatedNewItem',cleansedNewItem)
        // Use updatedNewItem in the request
        await post('api/invoices', cleansedNewItem);
        setNewItem({
            date: '',
            channel_ref: '',
            pip: '',
            item: '',
            pack_size: '',
            order_qnt: '',
            approved_qnt: '',
            unit_price_ex_vat: '',
            channel: '',
            sub_totals: '',
            received: '',
            notes: '',
          });
          
      fetchData(); // Refresh data after adding
      // onAddItem(); // Notify parent component about the addition
    } catch (error) {
      console.error('Error adding invoice:', error);
    }
  };
  
  const handleUpdateItem = async () => {

    console.log(selectedItemId)
    
    const cleansedUpdateItem = {
        ...updateItem,
        order_qnt: parseInt(updateItem.order_qnt),
        approved_qnt: parseInt(updateItem.approved_qnt),
        unit_price_ex_vat: parseFloat(updateItem.unit_price_ex_vat),
        sub_totals:parseFloat(updateItem.approved_qnt)* parseFloat(updateItem.unit_price_ex_vat),
      };
    
      // Validation
      if (!Number.isInteger(cleansedUpdateItem.order_qnt) || !Number.isInteger(cleansedUpdateItem.approved_qnt)) {
        console.warn('Order Quantity and Approved Quantity must be integers');
        return;
      }
    
      // if (isNaN(cleansedUpdateItem.unit_price_ex_vat) || isNaN(cleansedUpdateItem.sub_totals)) {
      //   console.warn('Unit Price (Ex VAT) and Subtotals must be numbers');
      //   return;
      // }
    
      if (cleansedUpdateItem.received !== 'YES' && cleansedUpdateItem.received !== 'NO'  && cleansedUpdateItem.received !== 'PENDING') {
        console.warn('Received value must be "YES" or "NO" or "PENDING"');
        return;
      }

    

    try {

      // Additional processing for 'Item' field
      // const [splitUpdatePip, splitUpdateItem] = cleansedUpdateItem.item.split(' - ');
      // const updatedUpdateItem = {
      //   ...cleansedUpdateItem,
      //   item: splitUpdateItem,
      //   pip: splitUpdatePip,
      // };

      // Use updatedUpdateItem in the request
      await put(`api/invoices/${selectedItemId}`, cleansedUpdateItem);

      fetchData(); // Refresh data after updating
      setSelectedItemId(null);
      setUpdateItem({
        date: '',
        channel_ref: '',
        pip: '',
        item: '',
        pack_size: '',
        order_qnt: '',
        approved_qnt: '',
        unit_price_ex_vat: '',
        channel: '',
        sub_totals: '',
        received: 'NO',
        notes: '',
      });      

      // onUpdateItem(); // Notify parent component about the update
    } catch (error) {
      console.error('Error updating invoice:', error);
    }
  };
  const handleDeleteItem = async (id) => {
    try {
      // Display confirmation dialog
      const isConfirmed = window.confirm("Are you sure you want to delete this item?");

      if (isConfirmed) {
        await del(`api/invoices/${id}`);
        fetchData(); // Refresh data after deleting
        // onDeleteItem(); // Notify parent component about the deletion
      }
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const handleUpdateButtonClick = (item) => {
    //console.log(item);
    setSelectedItemId(item.id);
    setUpdateItem({ ...item }); // Ensure 'updateItem' is populated correctly
    

  };

  const handleCloseUpdateItem = () => {
    setSelectedItemId(null);
    setUpdateItem({
      date: '',
      channel_ref: '',
      pip: '',
      item: '',
      pack_size: '',
      order_qnt: '',
      approved_qnt: '',
      unit_price_ex_vat: '',
      channel: '',
      sub_totals: '',
      received: 'NO',
      notes: '',
    });    

  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortData = (column) => {
    // Implement sorting logic based on the selected column
    // Update the 'data' state with the sorted array
  };

  // Filter options based on search term
  const filteredOptions = itemOptions.filter(item => item.product.toLowerCase().includes(searchTerm.toLowerCase()));

  // Update search term when input changes
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '15px',
      color: 'black',
      padding: '5px',
      borderWidth: '0px',
      width: '500px'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0, // remove border radius
    }),
    option: (provided, state) => ({
      ...provided,
      width: '500px',
      fontSize: '14px'
      // backgroundColor: state.isSelected ? '#007bff' : 'transparent', // highlight selected option
      // color: state.isSelected ? '#fff' : '#000', // text color for selected and non-selected options
    }),
  };



  // Validation functions
//   const isValidDate = (dateString) => {
//     const regex = /^\d{4}-\d{2}-\d{2}$/;
//     return regex.test(dateString);
//   };
  


  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className='table_back'>
      <h2>Invoices</h2>
      <div className="add-invoice">
      <button className="add-invoice-button" onClick={() => setShowPopup(true)}>
        + Add New Invoice Items
      </button>
    </div>
    {showPopup && (
          <div className="popup">
            <div className="popup-inner">
              <div className="update-form">
        {Object.keys(newItem).map((key) => (
          <div className="form-group" key={key}>
            {key === 'item' ? (
              <div className="field-container">
              <label className='form-labels'>
                Item:
                </label >
                <div className="select-dropdown">
<Select
  value={
    newItem.item
      ? { value: `${newItem.pip} - ${newItem.product}`, label: `${newItem.pip} - ${newItem.product}` }
      : { value: "", label: "Select item" }
  }
  onChange={(selectedOption) => setNewItem({ ...newItem, pip: selectedOption.pip, product: selectedOption.product, item: selectedOption.product })}
  options={itemOptions.map(item => ({ value: `${item.pip} - ${item.product}`, label: `${item.pip} - ${item.product}`, pip: item.pip, product: item.product }))}
  styles={customStyles}
/>

  
</div>
</div>
            ) : key === 'received' ? (
                <label className='form-labels'>
                  {key}:
                  <select
                  className='form-fields'
                    value={newItem[key] || ''} // Set a default empty value
                    onChange={(e) => setNewItem({ ...newItem, [key]: e.target.value })}
                  >
                    <option value="" disabled>Select an option</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                    <option value="PENDING">PENDING</option>
                  </select>
                </label>
              ) : key !== 'pip' && key !== 'sub_totals' && key !='product'? (
              <div>
                <label className='form-labels'>{key}:</label>
                <input className='form-fields'
                  type={(key === 'date' ? 'date' : key === 'order_qnt' || key === 'approved_qnt' ? 'number' : 'text')}
                  step={(key === 'order_qnt' || key === 'approved_qnt' ? '1' : 'any')}
                  value={newItem[key]}
                  onChange={(e) => setNewItem({ ...newItem, [key]: e.target.value })}
                />
              </div>
            ) : null}
          </div>
        ))}
            <button className="update-button" onClick={handleAddItem}>
             <BsCheckCircle />
            </button>
            <button className="close-button" onClick={() => setShowPopup(false)}>
           < BsXCircle />
          </button>
          </div>
        </div>
      </div>
    )}
            <table>
        <thead>
        <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key) =>
                key !== 'id' ? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>
  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <React.Fragment key={item.id}>
              <tr>
                {Object.keys(item).map((key) =>
                  key !== 'id' ? (
                    <td key={key}>
                                              {key === 'received' ? (
                            <div>
                              <label>
                                <select
                                  value={item[key]}
                                  onChange={(e) => handleUpdateReceived({ ...item, [key]: e.target.value })}
                                >
                                  <option value="YES">YES</option>
                                  <option value="NO">NO</option>
                                  <option value="PENDING">PENDING</option>
                                </select>
                              </label>
                            </div>
                          ) : (
                            key === 'date' ? new Date(item[key]).toISOString().split('T')[0] : item[key]
                          )}
                    </td>
                  ) : null
                )}
                <td>
                <button className="action-button" onClick={() => handleUpdateButtonClick(item)}>
                  <BsPencilSquare />
                  </button>
                  <button className="action-button" onClick={() => handleDeleteItem(item.id)}>
                  <BsFillTrash3Fill />
                  </button>
                </td>
              </tr>
              {selectedItemId === item.id && (
                <tr>
                  <td colSpan={Object.keys(item).length}>
                    <div>
                      <h3>Update Invoice</h3>
                      <div className="update-form">
                      {Object.keys(updateItem).map((key) => (
                        <div key={key} className="form-group">
                            {key === 'item' ? (
                            // <label>
                            //     Item:
                            //     <select
                            //     value={`${(updateItem.pip)} - ${updateItem.item}`}
                            //     onChange={(e) => setUpdateItem({ ...updateItem, Item: e.target.value })}
                            //     >
                            //     <option value="">Select an item</option>
                            //     {itemOptions.map((itemOption) => (
                            //         <option
                            //         key={itemOption.product}
                            //         value={`${itemOption.pip} - ${itemOption.product}`}
                            //         >
                            //         {`${itemOption.pip} - ${itemOption.product}`}
                            //         </option>
                            //     ))}
                            //     </select>
                            // </label>

<div className="field-container">
<label className='form-labels'>
      
Item:
</label >

<div className="select-dropdown">
{/* <Select
value={{ value: `${(updateItem.pip)} - ${updateItem.item}`, label: `${(updateItem.pip)} - ${updateItem.item}` }}
onChange={(selectedOption) => setUpdateItem({ ...updateItem, product: selectedOption.product, pip: selectedOption.pip })}
options={itemOptions.map(item => ({ value: `${(item.pip)} - ${item.product}`, label: `${(item.pip)} - ${item.product}`, pip: item.pip, product: item.product }))}

styles={customStyles}
/> */}


<Select
  value={
    updateItem.item
      ? { value: `${updateItem.pip} - ${updateItem.item}`, label: `${updateItem.pip} - ${updateItem.item}` }
      : { value: "", label: "Select item" }
  }
  onChange={(selectedOption) => setUpdateItem({ ...updateItem, pip: selectedOption.pip, product: selectedOption.product, item: selectedOption.product })}
  options={itemOptions.map(item => ({ value: `${item.pip} - ${item.product}`, label: `${item.pip} - ${item.product}`, pip: item.pip, product: item.product }))}
  styles={customStyles}
/>

</div>
</div>
                            ) : key === 'date' ? (
                                <div>
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                    type="date"
                                    value={updateItem.date ? new Date(updateItem.date).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
                                />
                                </div>
                            ) : key === 'received' ? (
                            <label className='form-labels'>
                                {key}:
                                <select className='form-fields'
                                value={updateItem[key]}
                                onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
                                >
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                                <option value="PENDING">PENDING</option>
                                </select>
                            </label> 
                            ) : key !== 'pip' && key !== 'id' && key !== 'sub_totals' && key !='product' ? (
                            <div>
                                <label className='form-labels'>{key}:</label>
                                <input className='form-fields'
                                type={(key === 'date' ? 'date' : key === 'order_qnt' || key === 'approved_qnt' ? 'number' : 'text')}
                                step={(key === 'order_qnt' || key === 'approved_qnt' ? '1' : 'any')}
                                value={updateItem[key]}
                                onChange={(e) => setUpdateItem({ ...updateItem, [key]: e.target.value })}
                                />
                            </div>
                            ) : null}
                        </div>
                        ))}
                        <button className="update-button" onClick={handleUpdateItem}>
                        <BsCheckCircle />
                        </button>
                        <button className="close-button" onClick={handleCloseUpdateItem}>
                        < BsXCircle />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>


      {showNotePopup && (
        <div className="popup" id="notePopup">
          <div className="popup-content">
          <button class="close-button"  onClick={() => setShowNotePopup(false)}>&times;</button>
            <h3>Add a Note</h3>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Enter your note here"
            />
            <button onClick={handleNoteSubmit}>Submit</button>
            <button onClick={() => setShowNotePopup(false)}>Cancel</button>
          </div>
        </div>
      )}
      <div>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={data.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>

  );
};

// Pagination component
const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="page-numbers">
      {pageNumbers.map((number) => (
        <span
          key={number}
          onClick={() => onPageChange(number)}
          className={currentPage === number ? "page-number selected" : "page-number"}
        >
          {number}
        </span>
      ))}
    </div>
  );
  
  
};

export default PendingInvoices;
