import React, { useState, useEffect } from 'react';
import { get, post, del } from '../services/ApiService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsFillTrash3Fill,BsPencilSquare,BsSearch,BsXCircle,BsCheckCircle   } from "react-icons/bs";
import Select from 'react-select';


const TableStockCount = () => {
  const [data, setData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchFilters, setSearchFilters] = useState({});
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [showThresholdOnly, setShowThresholdOnly] = useState(false); // New state for toggle
  const [showCalendar, setShowCalendar] = useState(false);
  const [itemOptions, setItemOptions] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    // Retrieve the value from localStorage or use a default value

    if (typeof window !== 'undefined') {
      // Your initialization code that relies on the browser environment
      return parseInt(localStorage.getItem('itemsPerPage')) || 100;
    }
    else{
      return 100;
    }
  });
  const [loading, setLoading] = useState(true);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filterColumn, setFilterColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
    
  useEffect(() => {

  
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await get('api/stockCount');
      //console.log(response.data);
      setData(response.data);
      setLoading(false);
      // sortData(response.data, 'saleDate', 'desc');
    } catch (error) {
      console.error('Error fetching stock count:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    paginateData();
  }, [currentPage, itemsPerPage, sortedData]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('itemsPerPage', itemsPerPage.toString());
      // Your initialization code that relies on the browser environment
    }
    else{
    // Save the 'Items per page' value to localStorage

  }
  }, [itemsPerPage]);

  useEffect(() => {
    paginateData();
  }, [currentPage, itemsPerPage, sortedData]);

  
  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (e, column) => {
    const { value } = e.target;
    setSearchFilters(prevFilters => ({ ...prevFilters, [column]: value }));
  };

  const handleColumnHeaderClick = (columnKey) => {
    if (!searchIconClicked) {
      handleSort(columnKey);
    }
  };
  

  const handleSearchIconClick = (columnKey) => {
    // setSortedColumn(null); // Reset sorting
    setFilterColumn(columnKey);
    setShowFilterPopover(true);
    setShowCalendar(columnKey === 'date');
    setSearchIconClicked(true); // Set search icon clicked
  };

  const handleFilterPopoverClose = () => {
    setShowFilterPopover(false);
    setSearchIconClicked(false); // Reset search icon clicked
    setSearchFilters('');
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setSearchFilters(prevFilters => ({ ...prevFilters, date: formattedDate }));
    setShowCalendar(false); // Hide the calendar after selection
  };
  const filteredData = data.filter(item => {
    return Object.entries(searchFilters).every(([key, value]) => {
      if (!value || key === 'id') return true; // Exclude 'id' from search
      if (key === 'date') {
        const itemDate = new Date(item[key]).toISOString().split('T')[0];
        const searchDate = new Date(value).toISOString().split('T')[0];
        return itemDate.includes(searchDate);
      }
      return String(item[key]).toLowerCase().includes(value.toLowerCase());
    });
  });

  const sortedDataPlus = sortedColumn
    ? filteredData.slice().sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      if (sortedColumn === 'date') {
        return sortDirection === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
      }
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    })
    : filteredData.slice();

const thresholdFilteredData = showThresholdOnly
    ? sortedDataPlus.filter(item => item.stockcount < 15 * item.last7)
    : sortedDataPlus;

  const sortData = (data, sortBy, order) => {
    const sorted = [...data].sort((a, b) => {
      if (order === 'asc') {
        return a[sortBy] - b[sortBy];
      } else {
        return b[sortBy] - a[sortBy];
      }
    });

    setSortedData(sorted);
  };

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setSortedData(data.slice(startIndex, endIndex));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const cellStyle = (stockCount, last7) => {
    const thresholdRed = 10 * last7;
    const thresholdAmber = 15 * last7;

    if (stockCount < thresholdRed) {
      return { backgroundColor: 'red' };
    } else if (stockCount < thresholdAmber) {
      return { backgroundColor: 'orange' };
    } else {
      return {};
    }
  };

  return (

    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2>Stock Count</h2>
          {/* <div>
            <label>Items per page:</label>
            <select value={itemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div> */}

          <label>
            Show Only Low stock - Depleting within 15 days:
            <input
              type="checkbox"
              checked={showThresholdOnly}
              onChange={() => setShowThresholdOnly(!showThresholdOnly)}
            />
          </label>  
          <table>
            <thead>
              {data.length > 0 && (
                <tr>
 {data.length > 0 &&
              Object.keys(data[0]).map((key) =>
                key !== 'id' ? (
<th key={key} >
  <div onClick={() => handleColumnHeaderClick(key)}>
  {key}{' '}
  {!searchIconClicked && sortedColumn === key && (sortDirection === 'asc' ? '▲' : '▼')}

  </div>

  <div>
  
  <button className="search-icon" onClick={() => handleSearchIconClick(key)}>
    < BsSearch  className="icon-search"/>
    </button>

  </div>
  {filterColumn === key && showFilterPopover && (
    <div className="popover">
      {key !== 'date' ? (
        <input
          type="text"
          placeholder={`Search ${key}`}
          value={searchFilters[key] || ''}
          onChange={(e) => handleSearch(e, key)}
        />
      ) : (
        <DatePicker
          selected={searchFilters.date ? new Date(searchFilters.date) : null}
          onChange={handleDateSelect}
          dateFormat="yyyy-MM-dd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
      <button onClick={handleFilterPopoverClose}><BsXCircle className="icon-search"/></button>
    </div>
  )}
</th>
                ) : null
              )}
                </tr>
              )}
            </thead>
            <tbody>
  {thresholdFilteredData.map((item) => (
                <React.Fragment key={item.ppid}>
                <tr>
      {Object.keys(item).map((key) => (
        <td key={key} style={key === 'stockcount' ? cellStyle(item.stockcount, item.last7) : {}}
        title={item[key]}>
          {item[key]}
        </td>
      ))}
    </tr>
    </React.Fragment>
  ))}
</tbody>

          </table>
          <div>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
            <span>{currentPage}</span>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * itemsPerPage >= data.length}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default TableStockCount;
